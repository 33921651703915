import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { ParagraphFarmCategoryFragment } from '../../generated/types';
import { IoMdPin } from '@react-icons/all-files/io/IoMdPin';
import { useRouter } from 'next/router';
import { LocationSearch } from './LocationSearch';
import Link from 'next/link';
import { PageContext } from '../../context/PageContext';
import { filtersToQuery } from '../../utils/filters';

interface TabSearchProps {
  tabs: ParagraphFarmCategoryFragment[];
}

interface TabProps {
  label: string;
  isActive?: boolean;
}

const TabItem: FC<TabProps> = ({ label, isActive }) => {
  return (
    <Tab
      py={3}
      px={{
        base: 3,
        md: 6,
      }}
      bg={isActive ? 'white' : 'rgba(255,255,255, .6)'}
      color={{
        base: isActive ? 'primary' : 'gray.500',
        md: 'primary',
      }}
      borderTopRadius="sm"
      border="none"
      fontWeight="bold"
      mr={2}
      _hover={{
        bg: 'white',
      }}
      _selected={{
        color: 'primary',
      }}
      whiteSpace="nowrap"
    >
      {label}
    </Tab>
  );
};

interface TabSearchTabProps {
  tab: ParagraphFarmCategoryFragment;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
}

export const TabSearchTab: FC<TabSearchTabProps> = ({
  tab,
  searchValue,
  setSearchValue,
}) => {
  const router = useRouter();
  const pageContext = useContext(PageContext);
  const [activeSubCategoryIds, setActiveSubcategoryIds] = useState<string[]>([
    `${tab.subCategories?.[0]?.id || 0}`,
  ]);
  const searchPath = useMemo<string>(() => {
    return (
      tab.searchPageLink?.url || pageContext.config?.mainSearchPage?.url || ''
    );
  }, [tab]);

  const pathParams = useMemo(() => {
    if (activeSubCategoryIds.length) {
      return {
        filters: filtersToQuery([
          {
            conditions: [
              {
                field: 'sub_categories',
                operator: 'IN',
                value: activeSubCategoryIds.join(','),
              },
            ],
          },
        ]),
      };
    }

    return null;
  }, [activeSubCategoryIds]);

  return (
    <Flex
      alignItems={{
        base: 'flex-start',
        md: 'center',
      }}
      justifyContent="space-between"
      flexDirection={{
        base: 'column',
        md: 'row',
      }}
    >
      <CheckboxGroup
        onChange={(value) => {
          setActiveSubcategoryIds(value as string[]);
        }}
        value={activeSubCategoryIds}
      >
        <Stack
          direction={{
            base: 'column',
            md: 'row',
          }}
          spacing={4}
        >
          {tab.subCategories.map((subCategory, j) => {
            return (
              <Checkbox key={subCategory.id} value={`${subCategory.id}`}>
                {subCategory.title}
              </Checkbox>
            );
          })}
        </Stack>
      </CheckboxGroup>

      <Box
        mt={{
          base: 4,
          md: 0,
        }}
      >
        <LocationSearch
          value={searchValue}
          onInputChange={(e) => {
            setSearchValue(e.target.value);
          }}
          onSubmit={() => {}}
          allowEmptySubmit
          submitPath={searchPath}
          pathParams={pathParams}
        />
      </Box>
    </Flex>
  );
};

export const TabSearch: FC<TabSearchProps> = ({ tabs }) => {
  const [index, setIndex] = useState(0);
  const [searchValue, setSearchValue] = useState('');

  return (
    <Box color="primary">
      <Box
        mx={4}
        mb={8}
        display={{
          base: 'block',
          md: 'none',
        }}
      >
        <Box
          fontFamily="heading"
          textAlign="center"
          mb={4}
          fontSize="xl"
          fontWeight="bold"
        >
          Anbieter finden
        </Box>
        <Stack direction="column">
          {tabs.map((tab) => (
            <Link
              key={`mobile-${tab.id}`}
              href={tab.searchPageLink?.url || '/'}
              passHref
            >
              <Flex
                alignItems="center"
                as="a"
                bg="primary"
                color="white"
                py={2}
                px={3}
                borderRadius="full"
                fontWeight="bold"
              >
                {tab.category.icon && (
                  <img src={tab.category.icon.url} width={30} />
                )}
                <Box ml={2}>{tab.category.title}</Box>
              </Flex>
            </Link>
          ))}
        </Stack>
      </Box>
      <Tabs
        display={{
          base: 'none',
          md: 'block',
        }}
        index={index}
        onChange={setIndex}
      >
        <TabList
          borderBottom="none"
          overflowX={{
            base: 'auto',
            md: 'hidden',
          }}
          overflowY="hidden"
        >
          {tabs.map((tab, i) => {
            return (
              <TabItem
                key={tab.id}
                label={tab.category.title}
                isActive={index === i}
              />
            );
          })}
        </TabList>

        <TabPanels>
          {tabs.map((tab, i) => {
            return (
              <TabPanel
                key={tab.id}
                py={4}
                px={6}
                bg="white"
                borderRadius="sm"
                borderTopLeftRadius="none"
                shadow={{
                  base: 'none',
                  md: 'lg',
                }}
              >
                <TabSearchTab
                  tab={tab}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                />
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </Box>
  );
};
