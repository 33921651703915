import { FC } from 'react';
import { ParagraphHeroFragment } from '../../../generated/types';
import { Box, Container, Img } from '@chakra-ui/react';
import { Image } from '../../Common/Image';
import { HTMLText } from '../../Common/HTMLText';
import { TabSearch } from '../../Common/TabSearch';

interface HeroProps {
  data: ParagraphHeroFragment;
}

export const Hero: FC<HeroProps> = ({ data }) => {
  return (
    <Box pos="relative" my={-6}>
      <Box pos="relative">
        <Image
          maxH="665px"
          objectFit="cover"
          imageStyle={data.image.imageStyle}
          image={data.image}
          loading="eager"
          hideCopyright
        />

        <Img
          src="/header_wave.svg"
          width="100%"
          pos="absolute"
          bottom={0}
          left={0}
          right={0}
        />
      </Box>

      <Box pos="absolute" left={0} right={0} bottom={0} top={0} zIndex={0}>
        <Container
          color="#fff"
          maxW="container.main"
          zIndex={1}
          pt={{
            base: 4,
            md: 20,
          }}
        >
          <HTMLText text={data.text} />
          <Box
            display={{
              base: 'none',
              md: 'block',
            }}
          >
            {data.searchBar && (
              <Box mt={20}>
                <TabSearch tabs={data.searchBar.searchCategories} />
              </Box>
            )}
          </Box>
        </Container>
      </Box>

      <Box
        pos="relative"
        zIndex={1}
        display={{
          base: 'block',
          md: 'none',
        }}
      >
        <Container
          color="#fff"
          maxW="container.main"
          zIndex={1}
          px={0}
          pt={{
            base: 4,
            md: 20,
          }}
        >
          {data.searchBar && (
            <TabSearch tabs={data.searchBar.searchCategories} />
          )}
        </Container>
      </Box>
    </Box>
  );
};
